import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import HomepageHeroSectionDesktop from './HomepageHeroSectionDesktop'
import HomepageHeroSectionTablet from './HomepageHeroSectionTablet'
import HomepageHeroSectionMobile from './HomepageHeroSectionMobile'
import useHomepageHeroSection from './useHomepageHeroSection'

const HomepageHeroSection = () => {
  const homepageHeroProps = useHomepageHeroSection()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobile) {
    return <HomepageHeroSectionMobile />
  }
  if (isTablet) {
    return <HomepageHeroSectionTablet {...homepageHeroProps} />
  }
  return <HomepageHeroSectionDesktop {...homepageHeroProps} />
}

export default HomepageHeroSection
