import { TrackedButton, CheckIcon } from '@flock/shared-ui'
import { Box, Typography, Grid } from '@mui/material'
import { navigate } from 'gatsby'
import React from 'react'
import HomepageHeroImage from '../../../images/homepage-hero-image.webp'
import { ONBOARDING_PATH } from '../../../routeConstants'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { WebinarModal } from '../../SharedComponents/WebinarModal'

const HomepageHeroSectionMobile = () => (
  <SectionLayout name="homepage-hero" backgroundColor="trustBlue.main">
    <Grid
      item
      sm={12}
      display="flex"
      flexDirection="column"
      gap="24px"
      justifyContent="center"
    >
      <Typography
        style={{
          fontSize: '40px',
          lineHeight: '48px',
          fontWeight: 800,
          fontFamily: 'Playfair Display',
        }}
        color="moneyGreen.main"
      >
        Landlords,
        <br />
        retire and
        <br />
        minimize taxes.
      </Typography>
      <Typography variant="h4" sx={{ fontSize: '1.25rem' }}>
        <div
          style={{
            display: 'flex',
            paddingBottom: '10px',
          }}
        >
          <CheckIcon width="24px" height="24px" />{' '}
          <span style={{ paddingLeft: '4px', fontSize: '1.25rem' }}>
            Defer taxes with the 721 exchange.
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            paddingBottom: '10px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <CheckIcon width="24px" height="24px" />{' '}
          </div>
          <span style={{ paddingLeft: '4px', fontSize: '1.25rem' }}>
            Exit all landlord responsibilities.
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            paddingBottom: '10px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <CheckIcon width="24px" height="24px" />{' '}
          </div>
          <span style={{ paddingLeft: '4px', fontSize: '1.25rem' }}>
            Earn steady income from a real estate fund.
          </span>
        </div>
      </Typography>
      <TrackedButton
        onClick={() => navigate(ONBOARDING_PATH)}
        size="small"
        sx={{
          width: 'unset!important',
          padding: '16px 16px',
        }}
        trackingConfig={{
          properties: {
            leadConversion: 'started',
          },
        }}
      >
        Get Started
      </TrackedButton>
      <Box display="flex" justifyContent="flex-end">
        <WebinarModal />
      </Box>
      <Grid item sm={12}>
        <Box component="img" width="100%" src={HomepageHeroImage} />
      </Grid>
    </Grid>
  </SectionLayout>
)

export default HomepageHeroSectionMobile
