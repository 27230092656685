import {
  CheckIcon,
  ActionTextField,
  AddressData,
  GOOGLE_MAPS_API_KEY,
} from '@flock/shared-ui'
import { Box, Typography, Grid, CircularProgress } from '@mui/material'
import React from 'react'
import SectionLayout from '../../SharedComponents/SectionLayout'
import HomepageHeroImage from '../../../images/homepage-hero-image-desktop.webp'
import { HomepageHeroSectionProps } from './homepageHeroSectionTypes'
import { DENVER_ADDRESS } from '../../../constants'
import { WebinarModal } from '../../SharedComponents/WebinarModal'

const AddressTextField = React.lazy(
  () =>
    import('@flock/shared-ui/src/components/AddressTextField/AddressTextField')
)

const HomepageHeroSectionDesktop = (props: HomepageHeroSectionProps) => {
  const {
    onKeyDown,
    onSubmit,
    loading,

    setAddressData,
  } = props

  const isSSR = typeof window === 'undefined'

  return (
    <SectionLayout name="homepage-hero" backgroundColor="trustBlue.main">
      <Grid
        item
        sm={6}
        display="flex"
        flexDirection="column"
        gap="32px"
        justifyContent="center"
      >
        <Typography variant="h1" fontWeight="800" color="moneyGreen.main">
          Landlords,
          <br />
          retire and
          <br /> minimize taxes.
        </Typography>
        <Typography variant="h4" sx={{ fontSize: '1.25rem' }}>
          <div
            style={{
              display: 'flex',
              paddingBottom: '10px',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <CheckIcon width="32px" height="32px" />{' '}
            </div>
            <Typography
              variant="h4"
              sx={{ paddingLeft: '4px', fontSize: '1.25rem' }}
            >
              Defer taxes with the 721 exchange.
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              paddingBottom: '10px',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <CheckIcon width="32px" height="32px" />{' '}
            </div>
            <Typography
              variant="h4"
              sx={{ paddingLeft: '4px', fontSize: '1.25rem' }}
            >
              Exit all landlord responsibilities.
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              paddingBottom: '10px',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <CheckIcon width="32px" height="32px" />{' '}
            </div>
            <Typography
              variant="h4"
              sx={{ paddingLeft: '4px', fontSize: '1.25rem' }}
            >
              Earn steady income from a real estate fund.
            </Typography>
          </div>
        </Typography>

        {!isSSR && (
          <React.Suspense
            fallback={
              <Grid item sm={12}>
                <ActionTextField
                  size="large"
                  variant="outlined"
                  label="Rental property address"
                  placeholder={DENVER_ADDRESS}
                  fullWidth
                  onSubmit={() => {}}
                />
              </Grid>
            }
          >
            <Grid item sm={12}>
              <AddressTextField
                size="large"
                variant="outlined"
                label="Your rental property address"
                placeholder={DENVER_ADDRESS}
                ctaContent={
                  loading ? (
                    <Box
                      sx={{
                        width: {
                          md: '116px',
                          xs: '93px',
                        },
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress size="24px" color="white" />
                    </Box>
                  ) : (
                    'Get a valuation'
                  )
                }
                onKeyDown={onKeyDown}
                fullWidth
                onChange={(data: AddressData | null) => setAddressData(data!)}
                onSubmit={onSubmit}
                googleMapsApiKey={GOOGLE_MAPS_API_KEY || ''}
              />
            </Grid>
          </React.Suspense>
        )}
        <Grid item sm={12} mt="-24px">
          <WebinarModal />
        </Grid>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5}>
        <Box component="img" width="448px" src={HomepageHeroImage} />
      </Grid>
    </SectionLayout>
  )
}

export default HomepageHeroSectionDesktop
