import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import HomepageHeroSection from '../components/HomepageComponents/HomepageHeroSection/HomepageHeroSection'
import { useRecordPageDuration } from '../components/utils'

const HomepageFAQSection = React.lazy(
  () => import('../components/HomepageComponents/HomepageFAQSection')
)

const HomepageCustomerStoriesSection = React.lazy(
  () =>
    import('../components/HomepageComponents/HomepageCustomerStoriesSection')
)
const HomepageContributorQuoteSection = React.lazy(
  () =>
    import('../components/HomepageComponents/HomepageContributorQuoteSection')
)
const HomepageProcessSection = React.lazy(
  () => import('../components/HomepageComponents/HomepageProcessSection')
)

const HomepageFeeCollateralSection = React.lazy(
  () => import('../components/HomepageComponents/HomepageFeeCollateralSection')
)

const HomepagePortfolioSection = React.lazy(
  () =>
    import(
      '../components/HomepageComponents/HomepagePortfolioSection/HomepagePortfolioSection'
    )
)

const HomepageOperationsSection = React.lazy(
  () =>
    import(
      '../components/HomepageComponents/HomepageOperationsSection/HomepageOperationsSection'
    )
)

const HomepageFeatureCard = React.lazy(
  () =>
    import(
      '../components/SharedComponents/HomepageFeatureCard/HomepageFeatureCard'
    )
)

const HomepageAboutUs = React.lazy(
  () => import('../components/HomepageComponents/HomepageAboutUs')
)

const HomepagePhoneConsultationCta = React.lazy(
  () =>
    import(
      '../components/HomepagePhoneConsultationCta/HomepagePhoneConsultationCta'
    )
)

const pageTitle = 'Flock | A better way to sell your rental property'
const pageDescription = `Retire from being a landlord.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const Home = () => {
  const isSSR = typeof window === 'undefined'
  useRecordPageDuration()

  const { getPropertySummaryDetails } = useStaticQuery(graphql`
    query {
      getPropertySummaryDetails {
        asOfDate
      }
    }
  `)

  return (
    <PageWrapper
      title={pageTitle}
      trackingName="homepage"
      headerBackground="trustBlue.main"
      asOfDate={getPropertySummaryDetails?.asOfDate}
    >
      <HomepageHeroSection />
      {!isSSR && (
        <React.Suspense fallback="">
          <HomepageFeatureCard />
          <HomepageCustomerStoriesSection />
          <HomepageFeeCollateralSection />
          <HomepageAboutUs />
          <HomepageProcessSection />
          <HomepagePortfolioSection />
          <HomepageOperationsSection />
          <HomepageFAQSection />
          <HomepageContributorQuoteSection />
          <HomepagePhoneConsultationCta />
        </React.Suspense>
      )}
    </PageWrapper>
  )
}
export default Home
